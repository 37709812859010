<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Templates Style'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Templates Style Edit'"
      :title="'Templates Style Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
        <div v-if="langList.length" class="form-create__langs">
          <div
            v-for="lang in langList"
            :key="lang.id"
            @click="openLangModal(lang)"
            class="form-create__lang"
          >
            {{ lang.seoName }}
          </div>
        </div>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <p class="form-create__label">SEO Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'seo_name'"
              :label="'seoName'"
              :value="formDataToSend.seo_name"
              :type="'text'"
              :placeholder="'SEO Name'"
              :error-messages="validationErrors['seoName']"
              readonly
              isBordered
            />
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Update</MainButton>
          </div>
        </form>
        <MainModal v-if="langModal" :on-close="closeLangModal" :modal-center="true">
          <div class="lang-modal">
            <div class="lang-modal__title">Translations for {{ chosenLang.name }}</div>
            <form @submit.prevent="handleSubmitLang">
              <p class="form-create__label">Name</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSendLang"
                  :id="'name'"
                  :label="'name'"
                  :type="'text'"
                  :placeholder="'name'"
                  isBordered
                />
                <div
                  @click="handleTranslate('name', chosenLang.name)"
                  class="form-create__translate"
                >
                  Translate (Chat GPT)
                </div>
              </div>
              <div class="form-create__btn">
                <MainButton :type="'submit'">Update</MainButton>
              </div>
            </form>
          </div>
        </MainModal>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import referencesApi from "~/api/references";
import langApi from "~/api/lang";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import MainModal from "~/components/molecules/MainModal.vue";

export default {
  name: "TemplatesStyleEdit",
  metaInfo: {
    title: "Templates Style Edit",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        seo_name: "",
        status: false,
      },
      formDataToSendLang: {
        name: "",
        id: null,
      },
      langEdit: false,
      langModal: false,
      chosenLang: null,
      style: null,
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainCheckbox,
    MainModal,
  },
  mounted() {
    this.getItem();
    this.getLang();
  },
  computed: {
    ...mapState("references", ["error", "loadingProcess", "validationErrors"]),
    ...mapState("lang", ["lang", "langList"]),
    getFirstLink() {
      return ROUTE.TEMPLATES_STYLE_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",
        seo_name:
          this.formDataToSend.seo_name.length != undefined
            ? this.formDataToSend.seo_name.trim()
            : "",
        status: this.formDataToSend.status ? 1 : 0,
      };
      const url = `/${this.getId}`;
      this.$store.dispatch("references/editTemplatesStyle", { url: url, editData: data });
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.TEMPLATES_STYLE_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getItem() {
      const url = `/${this.getId}`;
      try {
        referencesApi.getItemTemplatesStyle(url).then((res) => {
          this.$store.commit("references/setError", {});
          this.$store.commit("references/setValidationErrors", {});
          const data = res.data;
          this.style = res.data;
          this.formDataToSend.name = data.name;
          this.formDataToSend.seo_name = data.seoName;
          this.formDataToSend.status = data.status ? true : false;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getLang() {
      let url = `?page=${this.page}`;
      this.$store.dispatch("lang/getLangList", url);
    },
    closeLangModal() {
      this.chosenLang = null;
      this.langModal = false;
      this.formDataToSendLang.name = "";
      this.langEdit = false;
    },
    openLangModal(lang) {
      this.langModal = true;
      this.chosenLang = lang;

      const translate = this.style.translation;
      let translateStyle = translate.find((style) => style.langId === this.chosenLang.id);
      if (translateStyle) {
        this.formDataToSendLang.name = translateStyle.name;
        this.formDataToSendLang.id = translateStyle.id;
        this.langEdit = true;
      }
    },
    handleSubmitLang() {
      this.$store.commit("references/setLoading", true);
      const data = {
        name: this.formDataToSendLang.name,
        style_id: this.style.id,
        lang_id: this.chosenLang.id,
      };
      if (this.langEdit) {
        langApi
          .editLangStyle(data, `/${this.formDataToSendLang.id}`)
          .then(() => {
            this.$store.commit("references/setLoading", false);
            this.closeLangModal();
            this.getItem();
          })
          .catch((error) => {
            this.$store.commit("references/setLoading", false);
            console.log(error);
          });
      } else {
        langApi
          .createLangStyle(data)
          .then(() => {
            this.$store.commit("references/setLoading", false);
            this.closeLangModal();
            this.getItem();
          })
          .catch((error) => {
            this.$store.commit("references/setLoading", false);
            console.log(error);
          });
      }
    },
    handleTranslate(el, lang) {
      this.$store.commit("references/setLoading", true);
      const data = {
        text: this.formDataToSend[el],
        language: lang,
      };
      langApi
        .getTranslation(data)
        .then((res) => {
          this.$store.commit("references/setLoading", false);
          this.formDataToSendLang[el] = res.data.response;
        })
        .catch((error) => {
          this.$store.commit("references/setLoading", false);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
